import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import isEmail from "validator/lib/isEmail"
import isEmpty from "validator/es/lib/isEmpty"
import Cookie from "js-cookie"
import { useToast } from "@chakra-ui/react"
import { postForm } from "../api"
import Layout from "../components/layout"
import SectionHero from "../components/vbhc-research-group/section-hero"
import SectionOurMembers from "../components/vbhc-research-group/section-our-members"
import SectionOrgs from "../components/vbhc-research-group/section-orgs"
import SectionQuote from "../components/vbhc-research-group/section-quote"
import SectionPublishedResearch from "../components/vbhc-research-group/section-research"
import SectionOngoingResearch from "../components/vbhc-research-group/section-ongoing-research"
import SectionConferences from "../components/vbhc-research-group/section-confrences"
import SectionLearnMore from "../components/vbhc-research-group/section-learn-more"
import MessageConfirmation from "../components/contact-us/message-confirmation"

const createValidator = field => value => {
  if (field.required && isEmpty(value)) {
    return `${field.label} is required`
  }
  if (field.name === "email" && !isEmail(value)) {
    return "Please enter a valid email address"
  }
  return ""
}

const createField = fg => {
  const fields = fg.fields.map(field => ({
    ...field,
    id: field.name,
    validate: createValidator(field),
    value: "",
    hint: "",
  }))
  return fields.length > 1 ? fields : fields[0]
}

const VBHCResearchGroupIndex = props => {
  const publications = get(props, "data.allContentfulPublication.nodes")
  const submitText = get(props, "data.hubspotForm.submitText")
  const fieldGroups = get(props, "data.hubspotForm.formFieldGroups")
  const portalId = get(props, "data.hubspotForm.portalId")
  const inlineMessage = get(props, "data.hubspotForm.inlineMessage")
  const hutk = Cookie.get("hubspotutk")
  const pageUri = get(props, "location.href")
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const fields = React.useMemo(
    () => fieldGroups.map(fg => createField(fg)),
    [fieldGroups]
  )

  const handleSubmit = React.useCallback(
    data => {
      setLoading(true)
      const context = { hutk, pageUri, pageName: "VBHC Research Group" }
      postForm({
        portalId,
        formId: "9ebbcb8f-d920-41e0-bb9b-8ee409e99898",
        data,
        context,
      }).then(() => {
        setLoading(false)
        toast({
          duration: null,
          render: () => (
            <MessageConfirmation
              message={inlineMessage}
              onClose={() => {
                toast.closeAll()
              }}
              closeText="Close"
            />
          ),
        })
      })
    },
    [portalId, pageUri, inlineMessage, toast, hutk]
  )

  return (
    <Layout
      location={props.location}
      title={"Value-Based Healthcare Research Group | Avant-Garde Health"}
    >
      <SectionHero />
      <SectionOurMembers />
      <SectionOrgs />
      <SectionQuote />
      <SectionPublishedResearch publications={publications} />
      <SectionOngoingResearch />
      <SectionConferences />
      <SectionLearnMore
        fields={fields}
        onSubmit={handleSubmit}
        submitText={submitText}
        loading={loading}
      />
    </Layout>
  )
}

export default VBHCResearchGroupIndex

export const pageQuery = graphql`
  query hubspotFormAndPublicationsIndexQuery {
    hubspotForm(id: { eq: "9ebbcb8f-d920-41e0-bb9b-8ee409e99898" }) {
      portalId
      name
      submitText
      inlineMessage
      formFieldGroups {
        fields {
          label
          name
          required
          fieldType
        }
      }
    }
    allContentfulPublication(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
      filter: { tags: { in: ["Research"] } }
    ) {
      nodes {
        title
        id
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        link {
          link
        }
      }
    }
  }
`
