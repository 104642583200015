import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Heading,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

const styles = {
  root: {
    bg: "#F5F5F7",
    width: "100%",
    height: { base: "100%", md: "100%", lg: "536px" },
    px: "24px",
    pb: { base: "24px", md: "24px", lg: "0px" },
  },

  "heading-box": {
    margin: "0 auto",
    pt: "32px",
    width: { base: "100%", md: "100%", lg: "50%" },
    justifyContent: "center",
    alignItems: { base: "left", md: "left", lg: "center" },
  },
  "inner-box": {
    margin: "0 auto",
    maxWidth: { base: "100%", md: "100%", lg: "1111px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
    justifyContent: "space-between",
    height: { base: "100%", md: "100%", lg: "400px" },
  },
  "right-box": {
    flexDirection: "column",
    justifyContent: "center",
    mt: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
  },
  heading: {
    fontWeight: "bold",
    textAlign: { base: "left", md: "left", lg: "center" },
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#000000",
  },
  text: {
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  hero: {
    mt: { base: "24px", md: "24px", lg: "0px" },
    mr: { base: "0px", md: "0px", lg: "169.36px" },
    height: { base: "auto", md: "auto", lg: "320px" },
    width: { base: "100%", md: "100%", lg: "482.64px" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },
}

const SectionOurMembers = () => (
  <Box sx={styles.root}>
    <Box sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>Our Members</Heading>
    </Box>
    <Flex sx={styles["inner-box"]}>
      <Box sx={styles.hero}>
        <StaticImage
          src="../../images/vbhc/our-members.png"
          alt="Illustration of a group of doctors and nurses"
          sx={styles.hero}
        />
      </Box>
      <Flex sx={styles["right-box"]}>
        <Text sx={styles.text}>
          Value-Based Healthcare Research Group members include surgeons from
          across the country, representing:
        </Text>
        <UnorderedList width="auto" listStylePosition="inside">
          <ListItem> academic medical centers</ListItem>
          <ListItem> hospitals</ListItem>
          <ListItem> health care systems</ListItem>
          <ListItem> ambulatory surgery centers</ListItem>
        </UnorderedList>
      </Flex>
    </Flex>
  </Box>
)

export default SectionOurMembers
