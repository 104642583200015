import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Box, Heading, Text } from "@chakra-ui/react"
import Form from "../splash/form"

const styles = {
  root: {
    width: "100%",
    px: "24px",
  },
  "inner-box": {
    margin: "0 auto",
    width: { base: "100%", md: "100%", lg: "1112px" },
    display: "flex",
    my: { base: "24px", md: "24px", lg: "32px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },

    justifyContent: "space-between",
  },

  "heading-box": {
    display: "flex",
    flexDirection: "column",
    width: { base: "100%", md: "100%", lg: "429px" },
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    color: "#000000DE",
  },

  image: {
    my: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
    height: { base: "auto", md: "auto", lg: "265.528px" },
    width: { base: "100%", md: "100%", lg: "429px" },
  },
  form: {
    width: { base: "100%", md: "100%", lg: "500px" },
  },
}

const SectionLearnMore = ({ fields, onSubmit, loading, submitText }) => (
  <Flex sx={styles.root}>
    <Box sx={styles["inner-box"]}>
      <Box sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
          Learn More About the Value-based Healthcare Research Group
        </Heading>

        <Text
          sx={{ color: "#041424", mt: { base: "24px", md: "24px", lg: "8px" } }}
        >
          Please contact us to learn more about the Value-based Healthcare
          Research Group. We’re happy to answer your questions and welcome you
          to join us!
        </Text>

        <Box sx={styles.image}>
          <StaticImage
            src="../../images/vbhc/vbhc-learn-more.png"
            alt="Illustration of a group of people looking at an oversized healthcare checklist on a clipboard in front of a blue background. Click here to learn how hospitals, ASCs, and physicians use our healthcare data analytics platform to reduce costs and improve patient outcomes."
            placeholder="blurred"
          />
        </Box>
      </Box>
      <Box sx={styles.form}>
        <Form
          fields={fields}
          loading={loading}
          onSubmit={onSubmit}
          submitText={submitText}
        />
      </Box>
    </Box>
  </Flex>
)

SectionLearnMore.propTypes = {
  fields: PropTypes.array,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

export default SectionLearnMore
