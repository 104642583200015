import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Text, chakra } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    height: "144px",
    border: "1px solid #DCE0E5",
    borderRadius: "8px",
    px: "16px",
    alignItems: "center",
  },
  icon: {
    minHeight: "40px",
    minWidth: "40px",
    height: "40px",
    width: "40px",
    cursor: "pointer",
  },
  text: {
    ml: "16px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
}

const ResearchHighlight = ({ Image, text, styles, link, TextComponent }) => {
  const _styles = merge({}, defaultStyles, styles)

  return (
    <Flex sx={_styles.root}>
      {TextComponent ? (
        <TextComponent />
      ) : (
        <Text sx={_styles.text}>{text}</Text>
      )}

      <chakra.a
        sx={_styles.icon}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image />
      </chakra.a>
    </Flex>
  )
}

ResearchHighlight.propTypes = {
  Image: PropTypes.func,
  text: PropTypes.string,
  styles: PropTypes.object,
  link: PropTypes.string,
}

ResearchHighlight.defaultProps = {
  Image: () => (
    <StaticImage
      src="../images/arrow-right.png"
      alt="Right Arrow"
      placeholder="blurred"
    />
  ),
  styles: {},
  onClickIcon: () => {},
}

export default ResearchHighlight
