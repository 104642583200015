import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Grid, GridItem, Box, Link, Flex } from "@chakra-ui/react"

const styles = {
  root: {
    height: "100%",
    my: { base: "24px", md: "24px", lg: "32px" },
  },
  "inner-box": {
    margin: "0px auto",
    px: { base: "24px", md: "24px", lg: "12px" },
    maxWidth: { base: "100%", md: "100%", lg: "1112px" },
  },
  "grid-item": {
    vertialAlign: "center",
    p: "24px",
  },
}

const SectionOrgs = () => (
  <Box sx={styles.root}>
    <Box sx={styles["inner-box"]}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="130px"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Box sx={{ width: "340px" }}>
          <Link href="https://rothmanortho.com/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-rothman.png"
              alt="Rothman Othopedics logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
        <Box sx={{ width: "320px" }}>
          <Link href="https://www.massgeneral.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-mgh.png"
              alt="Massachusetts General Hospital logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
        <Box sx={{ width: "160px" }}>
          <Link href="https://www.emoryhealthcare.org" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-emory.png"
              alt="Emory Healthcare logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="130px"
        px="168px"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Box sx={{ width: "291px" }}>
          <Link href="https://www.whhs.com/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-washington.png"
              alt="Washington Hospitcal Healthcare System logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
        <Box sx={{ width: "220px" }}>
          <Link href="https://www.cooperhealth.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-cooper.png"
              alt="Cooper University Health Care logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="130px"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Box sx={{ width: "248px" }}>
          <Link href="https://www.hopkinsmedicine.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-johns-hopkins.png"
              alt="Johns Hopkins Medicine logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
        <Box sx={{ width: "294px" }}>
          <Link href="https://www.nwh.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-nwh.png"
              alt="Newton Wellesley Hospital logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
        <Box sx={{ width: "360px" }}>
          <Link href="https://www.pennstatehealth.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-penn-state.png"
              alt="Penn State Health logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="130px"
        px="168px"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <Box sx={{ width: "320px" }}>
          <Link href="https://www.nebh.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-nebh.png"
              alt="New England Baptist Hospital logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
        <Box sx={{ width: "320px", display: "flex", justifyContent: "center" }}>
          <Link href="https://www.panamclinic.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-pan-am.jpg"
              alt="Pan Am Clinic logo"
              placeholder="blurred"
            />
          </Link>
        </Box>
      </Flex>
      <Grid
        justifyItems="center"
        alignItems="center"
        display={{ base: "grid", md: "grid", lg: "none" }}
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        width={{ base: "100%", md: "100%" }}
        margin="0 auto"
        px={{ base: "24px", md: "24px" }}
      >
        <GridItem sx={styles["grid-item"]}>
          <Link href="https://rothmanortho.com/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-rothman.png"
              alt="Rothman Othopedics logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>
        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.massgeneral.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-mgh.png"
              alt="Massachusetts General Hospital logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.emoryhealthcare.org" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-emory.png"
              alt="Emory Healthcare logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>
        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.whhs.com/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-washington.png"
              alt="Washington Hospitcal Healthcare System logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.cooperhealth.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-cooper.png"
              alt="Cooper University Health Care logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>
        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.hopkinsmedicine.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-johns-hopkins.png"
              alt="Johns Hopkins Medicine logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.nwh.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-nwh.png"
              alt="Newton Wellesley Hospital logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>
        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.pennstatehealth.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-penn-state.png"
              alt="Penn State Health logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.nebh.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-nebh.png"
              alt="New England Baptist Hospital logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>
        <GridItem sx={styles["grid-item"]}>
          <Link href="https://www.panamclinic.org/" isExternal>
            <StaticImage
              src="../../images/vbhc/vbhc-pan-am.jpg"
              alt="Pan Am Clinic logo"
              placeholder="blurred"
            />
          </Link>
        </GridItem>
      </Grid>
    </Box>
  </Box>
)

export default SectionOrgs
