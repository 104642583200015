import React from "react"
import { navigate } from "gatsby"
import {
  Flex,
  Heading,
  Box,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

const styles = {
  root: {
    px: "24px",
    pb: "32px",
    bg: "#F5F5F7",
    height: { base: "auto", md: "auto", lg: "472px" },
    flexDirection: "column",
  },

  flex: {
    flexDirection: "column",
    alignItems: "center",
  },

  "heading-box": {
    margin: { base: "0px", md: "0x", lg: "0 auto" },
  },

  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    textAlign: { base: "left", md: "left", lg: "center" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    my: { base: "24px", md: "24px", lg: "32px" },
  },

  text: {
    fontSize: "16px",
    color: "#041424",
    lineHeight: "24px",
    letterSpacing: "0.024px",
  },

  button: {
    bg: "#129459",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    mt: { base: "24px", md: "24px", lg: "32px" },
    height: "56px",
    width: { base: "327px", md: "327px", lg: "248px" },
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us")
const SectionOngoingResearch = () => (
  <Flex sx={styles.root}>
    <Box sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>Ongoing Research Projects</Heading>
    </Box>
    <Flex sx={styles.flex}>
      <UnorderedList>
        <ListItem sx={styles.text}>
          Defining and Quantifying Value in Surgical Care
        </ListItem>
        <ListItem sx={styles.text}>
          Quantifying Costs and Improving Efficiency in Joint Arthroplasty
        </ListItem>
        <ListItem sx={styles.text}>
          Mid- and Long-Term Patient Mortality Following Joint Arthroplasty
        </ListItem>
        <ListItem sx={styles.text}>
          Role of TDABC and Digital Solutions in Lowering Costs and Improving
          Value
        </ListItem>
        <ListItem sx={styles.text}>
          Hospital Readmissions and Post-operative Complications After Total
          Shoulder Arthroplasty
        </ListItem>
        <ListItem sx={styles.text}>
          Trends in Incidence of Revision Surgeries After Total Joint
          Arthroplasty
        </ListItem>
        <ListItem sx={styles.text}>
          Trends in Total Joint Arthroplasty After Removal from CMS’s Inpatient
          Only List
        </ListItem>
        <ListItem sx={styles.text}>
          Adoption of Outpatient Joint Arthroplasties and Risk of Adverse
          Outcomes
        </ListItem>
        <ListItem sx={styles.text}>
          Payment Variation for Stroke Treatment and Its Implications for
          Value-Based Reimbursement
        </ListItem>
        <ListItem sx={styles.text}>
          Applying Machine Learning and Artificial Intelligence in Managing
          Operating Room and Hospital Readmissions
        </ListItem>
      </UnorderedList>
      <Button sx={styles.button} onClick={navigateToContactUs}>
        Contact Us to Learn More
      </Button>
    </Flex>
  </Flex>
)

export default SectionOngoingResearch
